export function cacheSaml(saml: string|null) {
    if (saml == null)
        return;
    
    sessionStorage.setItem('saml', saml);
}

export function removeJwt() {
    sessionStorage.removeItem('saml');
}

export function getSaml(): string | null {
    return sessionStorage.getItem('saml');
}