import {
  getJwt,
  removeJwt,
} from "#app/(unauthorized)/authentication/jwtStorageService";
import * as authenticationService from "#app/_api/authentication-service";
import config from "#app/env";
import { TimeSpan } from "@/lib/TimeSpan";
import { killAllTimers } from "@/lib/activityTimer";
import { logInfo, logTrace } from "@/lib/logger";
import { isEmpty, throttle } from "lodash";

export interface Claims {
  acc: string;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  oat: string;
  ra: string;
  seg: string;
  session: string;
  sub: string;
  obo: string;
}

export interface ClaimsObo {
  acc: jwtAccount[];
  seg: number;
  sub: string;
}

export enum Segment {
  NotLoggedIn = -1,
  IndividualInvestors = 0,
  CorporateEntities = 2,
  PrivateAssetManagement = 10,
  Preclient = 11,
}

export type jwtAccount = {
  num: string;
};

export class Jwt {
  private anonymousApis = new Set(["authentication", "registration"]);
  private jwt: string | null = null;
  private claims: Claims | null = null;
  private claimsObo: ClaimsObo | null = null;
  constructor() {
    logTrace("Constructing JWT");
    if (globalThis.window !== undefined) {
      this.jwt = getJwt();

      if (this.jwt) {
        this.claims = JSON.parse(atob(this.jwt.split(".")[1]!)) as Claims;
        if (this.isImpersonated) {
          this.claimsObo = JSON.parse(this.claims!.obo) as ClaimsObo;
        }
      }
    }
  }

  public get jwtAsString(): string | null {
    return this.jwt;
  }

  public get segment(): Segment {
    var segment = this.isImpersonated ? this.claimsObo?.seg : this.claims?.seg;
    if (segment == null) return Segment.NotLoggedIn;

    return +segment!;
  }

  public get subject(): string {
    var sub = this.isImpersonated ? this.claimsObo?.sub : this.claims?.sub;

    return sub ?? "";
  }

  public get requiredActivities(): string[] {
    return this.claims && !isEmpty(this.claims.ra)
      ? this.claims?.ra.split(" ")
      : [];
  }

  public get expiration(): number {
    return this.claims?.exp || -1;
  }

  public get oat(): string {
    return this.claims?.oat || "";
  }

  public get isImpersonated(): boolean {
    return !!this.claims?.obo;
  }

  public get jwtAccounts(): jwtAccount[] {
    if (this.isImpersonated) return this.claimsObo!.acc!;
    else return JSON.parse(this.claims!.acc!) as jwtAccount[];
  }

  public get accounts(): [string] | string[] {
    return this.jwtAccounts.map((acnt) => acnt.num);
  }

  public getAllAccountIndex(): number[] {
    return this.jwtAccounts.reduce((prev, _, index: number) => {
      return [...prev, index];
    }, [] as number[]);
  }

  public getAccountIndex(accountNumbers: string[]): number[] {
    return this.jwtAccounts.reduce((prev, curr, index: number) => {
      if (accountNumbers.includes(curr.num)) {
        return [...prev, index];
      }

      return prev;
    }, [] as number[]);
  }

  public getAccountFromIndex(accountIndices: number[]): string[] {
    return accountIndices.map((x) => this.jwtAccounts[x]!.num);
  }

  public logout(redirectOnLogout: boolean) {
    return throttle(async () => {
      return authenticationService.logout().then(() => {
        removeJwt();
        sessionStorage.clear();
        refreshCurrentJwt();
        killAllTimers();
        logInfo("User is logged out.");
        if (redirectOnLogout) {
          window.location.href = "/sign-out";
        }
      });
    }, 500)();
  }

  private getTimerRefreshDelta(): TimeSpan {
    return TimeSpan.fromSeconds(config.jwtTimerRefreshDelta);
  }

  public getJwtDelay(): TimeSpan {
    const expirationInMillis = this.expiration * 1000;
    if (expirationInMillis < 0) {
      throw new Error("JWT Expired");
    }

    logTrace("session expiration: " + expirationInMillis);

    const expiryTimeDelta = TimeSpan.fromMilliseconds(
      expirationInMillis - Date.now(),
    );

    logTrace("session expiration - now: " + expiryTimeDelta.totalMilliseconds);

    const refreshDelta = this.getTimerRefreshDelta();

    return expiryTimeDelta.subtract(refreshDelta);
  }

  private getApiNameFromRoute(apiRoute: string): string {
    let splitRoute: string[] = [];
    if (apiRoute) {
      splitRoute = apiRoute.split("/");
    }

    if (splitRoute.length <= 1) {
      throw new Error("Invalid apiRoute");
    }

    return splitRoute[2]!;
  }

  public getExternalGatewayHeaders(
    apiRoute: string,
  ): Record<string, string> | undefined {
    const apiName = this.getApiNameFromRoute(apiRoute);
    if (
      !this.anonymousApis.has(apiName) &&
      this.jwt &&
      this.segment !== Segment.NotLoggedIn
    ) {
      return {
        Authorization: `Bearer ${this.oat}`,
        jwt: this.jwt,
      };
    }

    return;
  }
}

let jwt = new Jwt();
export function getCurrentJwt(refresh: boolean = false) {

  if (refresh) {
    refreshCurrentJwt();
  }
  return jwt;
}

export function refreshCurrentJwt() {
  logTrace("Refreshing current JWT");
  jwt = new Jwt();
  emitChange();
}

type JwtChangeListener = () => void;
let listeners: JwtChangeListener[] = [];
export function subscribe(listener: JwtChangeListener) {
  listeners = [...listeners, listener];
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
}

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
