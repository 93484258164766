

export function logPrint(message: any) {
    console.log(message);
}


export function logError(error: any, message?: String) {
    console.error(error);
    console.error(message);
    window.dtrum && window.dtrum.reportError(error);
}


export function logInfo(message: any) {
    console.info(message);
}

export function logTrace(message: any) {
    console.info(message);
}
