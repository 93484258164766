export function cacheJwt(jwt: string) {
    sessionStorage.setItem('jwt', jwt);
}

export function removeJwt() {
    sessionStorage.removeItem('jwt');
}

export function getJwt(): string | null {
    return sessionStorage.getItem('jwt');
}