import { getCurrentJwt } from '#app/(unauthorized)/authentication/jwt';
import config from '#app/env';
import ky, { HTTPError } from 'ky';
import { trimStart } from 'lodash';
import { TimeSpan } from './TimeSpan';

const timeout: number = TimeSpan.fromSeconds(30).totalMilliseconds;

export const bolApi = ky.extend({
    prefixUrl: config.gatewayBaseUrl,
    timeout: timeout,
	hooks: {
		beforeRequest: [
			request => {       
                request.headers.set('PdsClientVer', '2');
                request.headers.set('PdsClientType', 'Web');
                const jwt = getCurrentJwt();
                const authHeaders = jwt.getExternalGatewayHeaders(new URL(request.url).pathname);
                if (authHeaders) {
                    for(const key in authHeaders) {
                        request.headers.set(key, authHeaders[key]!);
                    }
                }
			}
		],
        beforeError: [
            error => {
                return new ApiError(error.response.status, error);
            }
        ]
	}
});

export const fetchClient = ky.extend({
    timeout,
    hooks: {
        beforeError: [
            error => {
                return new ApiError(error.response.status, error);
            }
        ]
    }
});


const trimLeadingSlash = (value: string) => trimStart(value, '/');
export interface ApiErrorResult {
    errors?: { [key: string]: string[] }
}

export class ApiError extends HTTPError {
    constructor(public code: number, httpError: HTTPError) {
        super(httpError.response, httpError.request, httpError.options);
    }
}

export async function remove(apiUrl: string, headers?: HeadersInit, body? : any) {
    return bolApi.delete(trimLeadingSlash(apiUrl), { headers, json: body }).text();
}

export function getJson<T = void>(apiUrl: string, headers?: HeadersInit) {
    return bolApi.get(trimLeadingSlash(apiUrl), { headers }).json<T>();
}

export function getBlob(apiUrl: string, headers?: HeadersInit) {
    return bolApi.get(trimLeadingSlash(apiUrl), { headers }).blob();
}

export function getText(apiUrl: string, headers?: HeadersInit) {
    return bolApi.get(trimLeadingSlash(apiUrl), { headers }).text();
}

export function postJsonWithoutBody<T = void>(apiUrl: string, headers?: HeadersInit) {
    return postJson<T>(trimLeadingSlash(apiUrl), null, headers);
}

export function postJson<T = void>(apiUrl: string, body: any, headers?: HeadersInit) {
    return bolApi.post(trimLeadingSlash(apiUrl), { json: body, headers }).json<T>();
}

export function postJsonWithResponse(apiUrl: string, body: any, headers?: HeadersInit) {
    return bolApi.post(trimLeadingSlash(apiUrl), { json: body, headers });
}

export function postBlob(apiUrl: string, body: any, headers?: HeadersInit) {

    return bolApi.post(trimLeadingSlash(apiUrl), { headers, json: body }).blob();
}


export function putJson<T = void>(apiUrl: string, body?: any, headers?: HeadersInit) {
    return bolApi.put(trimLeadingSlash(apiUrl), { json: body, headers }).json<T>();
}

export function putText(apiUrl: string, body: any, headers?: HeadersInit) {
    return bolApi.put(trimLeadingSlash(apiUrl), { json: body, headers }).text();
}

export function patchJson<T = void>(apiUrl: string, body: any, headers?: HeadersInit) {
    return bolApi.patch(trimLeadingSlash(apiUrl), { json: body, headers }).json<T>();
}