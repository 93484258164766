import {
  Segment,
  getCurrentJwt,
  refreshCurrentJwt,
} from "#app/(unauthorized)/authentication/jwt";
import {
  cacheJwt,
  getJwt,
  removeJwt,
} from "#app/(unauthorized)/authentication/jwtStorageService";
import { startJwtRefreshTimer } from "#app/(unauthorized)/authentication/jwtTokenRefreshTimer";
import { cacheSaml } from "#app/(unauthorized)/authentication/samlStorageService";
import {
  MfaChallenge,
  MfaPhone,
  MfaResult,
} from "#app/_ui/components/mfa/shared";
import {
  ApiError,
  getJson,
  getText,
  postJson,
  postJsonWithoutBody,
  putJson,
} from "@/lib/fetchClient";

export type MfaChallengeType = "OOBSMS" | "TeleSign2FACall" | "EXTERNAL_METHOD1";
export type MfaResponse = {
  mfaSessionValueEncrypted: string;
  mfaAllowedChallengeTypes: MfaChallengeType[];
}

export interface AuthorizeResult {
  jwt: string;
  mfa: MfaResponse | null;
  deviceCookie: string | null;
  saml: string | null;
}

export type OneTimePassResult = {
  status: number;
  jwt: string;
};

export interface MessageServiceStatus {
  statusMessage: string;
  isAvailable: boolean;
}

export type ForgotMfaResponse = {
  status: number;
  loginId: string;
  response: MfaResponse;
};

export interface MobileResult {
  userId: string;
  loginId: string;
  token: string;
  expirationDate: Date;
  usedDate: Date;
  systemId: string;
  pin: string;
  auditId: string;
}

export interface ImpersonateResult {
  jwt: string;
  mfa: MfaResponse | null;
  deviceCookie: string | null;
}

const GATEWAY_PATH = "authentication/v1";

export async function authorize(
  username: string,
  password: string,
  _includeSaml: boolean,
) {
  const deviceCookie = encodeURIComponent(
    localStorage.getItem("deviceCookie") || "",
  );
  const deviceInfo = window.urlEncode(window.add_deviceprint());

  const result = await postJson<AuthorizeResult>(
    `/${GATEWAY_PATH}/Authorize?deviceTokenCookie=${deviceCookie}&deviceInfo=${deviceInfo}`,
    {
      userId: username,
      password: password,
    },
    {
      includeSaml: _includeSaml.toString(),
    },
  );

  localStorage.setItem("deviceCookie", result.deviceCookie || "");
  cacheSaml(result.saml);
  startSession(result.jwt);

  return result;
}

export function startSession(jwtStr: string) {
  cacheJwt(jwtStr);
  const jwt = getCurrentJwt(true);

  if (jwt.segment != Segment.NotLoggedIn) {
    startJwtRefreshTimer();
  }
}

export async function token() {
  const jwt = getJwt();
  const result = await getJson<AuthorizeResult>(`/${GATEWAY_PATH}/Token`, {
    JWT: jwt || "",
    ReevalRA: "true",
  });
  cacheJwt(result.jwt);
  refreshCurrentJwt();
  return result;
}

export async function changePassword(
  password: string,
  newPassword: string,
): Promise<string | null> {
  const jwt = getJwt();
  return await putJson(
    `/${GATEWAY_PATH}/Password`,
    { password: password, newPassword: newPassword },
    { JWT: jwt || "" },
  );
}

export async function acceptTerms() {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  await postJsonWithoutBody(
    `/${GATEWAY_PATH}/TermsAndConditions/BairdOnline/Accept`,
    headersInit,
  );
}

export async function forgotUser(email: string) {
  await postJson(`/${GATEWAY_PATH}/User/Forgot`, { email: email });
}

export async function forgotPassword(userId: string) {
  await getJson(
    `/${GATEWAY_PATH}/Password/Forgot?loginId=${encodeURIComponent(userId)}`,
  );
}

export async function getTerms() {
  const result = await getText(
    `/${GATEWAY_PATH}/TermsAndConditions/BairdOnline`,
  );
  return result;
}

export async function getServiceStatus(): Promise<MessageServiceStatus> {
  const result = await getJson<MessageServiceStatus>(
    `/${GATEWAY_PATH}/Message/ServiceStatus?type=web`,
    {},
  );
  return result;
}

export async function challengePhones(
  challenge: MfaChallenge,
): Promise<MfaPhone[]> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const result = await postJson<MfaPhone[]>(
    `/${GATEWAY_PATH}/Challenge/Phones?mfaSession=${challenge.session}`,
    {},
    headersInit,
  );

  for (var i = 0; i < result.length; i++) (result[i] as MfaPhone).index = i;

  return result;
}

export async function challengeSms(
  challenge: MfaChallenge,
  index: number,
): Promise<void> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  await postJson(
    `/${GATEWAY_PATH}/Challenge/Sms?mfaSession=${challenge.session}&phoneNumberIndex=${index}&deviceInfo=${deviceInfo}`,
    {},
    headersInit,
  );
}

export async function challengeSmsCheck(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  const result = await postJson<OneTimePassResult>(
    `/${GATEWAY_PATH}/Challenge/SmsCheck?mfaSession=${challenge.session}&oneTimePass=${pin}&deviceInfo=${deviceInfo}`,
    {},
    headersInit,
  );
  return {
    data: result.jwt,
    status: result.status,
  };
}

export async function challengeCall(
  challenge: MfaChallenge,
  index: number,
): Promise<void> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  await postJson(
    `/${GATEWAY_PATH}/Challenge/Call?mfaSession=${challenge.session}&phoneNumberIndex=${index}&deviceInfo=${deviceInfo}`,
    {},
    headersInit,
  );
}

export async function challengeCallCheck(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  const result = await postJson<OneTimePassResult>(
    `/${GATEWAY_PATH}/Challenge/CallCheck?mfaSession=${challenge.session}&oneTimePass=${pin}&deviceInfo=${deviceInfo}`,
    {},
    headersInit,
  );
  return {
    data: result.jwt,
    status: result.status,
  };
}

export async function challengeFaCodeCheck(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const jwt = getJwt();
  const headersInit: HeadersInit = { JWT: jwt || "" };
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  const result = await postJson<OneTimePassResult>(
    `/${GATEWAY_PATH}/Challenge/FaCodeCheck?mfaSession=${challenge.session}&oneTimePass=${pin}&deviceInfo=${deviceInfo}`,
    {},
    headersInit,
  );
  return {
    data: result.jwt,
    status: result.status,
  };
}

// CHANGE TO MUTATION AND THEN WE CAN INVALIDATE ALL QUERIES AFTER LOGOUT
export async function logout() {
  const jwt = getJwt();
  if (jwt) {
    const headersInit: HeadersInit = { JWT: jwt || "" };
    try {
      await postJsonWithoutBody(`/${GATEWAY_PATH}/Logout`, headersInit);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.code == 401) {
          removeJwt();
          return;
        }
      }
      throw error;
    }
  }
}

export async function begin(emailId: string): Promise<MfaChallenge> {
  const deviceInfo = window.urlEncode(window.add_deviceprint());

  const deviceCookie = localStorage.getItem("deviceCookie") || "";
  const result = await postJson<ForgotMfaResponse>(
    `/${GATEWAY_PATH}/Password/ForgotMfaBegin?emailId=${encodeURIComponent(emailId)}&deviceInfo=${deviceInfo}&deviceTokenCookie=${encodeURIComponent(deviceCookie)}`,
    {},
  );

  return {
    data: { loginId: result.loginId },
    session: result.response.mfaSessionValueEncrypted,
    allowedChallengeTypes: result.response.mfaAllowedChallengeTypes,
    deviceCookie: deviceCookie || "",
  };
}

export async function getPhones(challenge: MfaChallenge): Promise<MfaPhone[]> {
  try {
    const result = await getJson<MfaPhone[]>(
      `/${GATEWAY_PATH}/Password/ForgotMfaPhoneList?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}`,
    );

    for (var i = 0; i < result.length; i++) (result[i] as MfaPhone).index = i;

    return result;
  } catch (e) {
    //TODO should we throw an exception with a status code??
    return [];
  }
}

export async function sendMFASms(
  challenge: MfaChallenge,
  index: number,
): Promise<void> {
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  await postJson(
    `/${GATEWAY_PATH}/Password/ForgotMfaRequestSms?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}&phoneNumberIndex=${index}&deviceInfo=${deviceInfo}`,
    {},
  );
}

export async function sendMFACall(
  challenge: MfaChallenge,
  index: number,
): Promise<void> {
  const deviceInfo = window.urlEncode(window.add_deviceprint());
  await postJson(
    `/${GATEWAY_PATH}/Password/ForgotMfaRequestCall?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}&phoneNumberIndex=${index}&deviceInfo=${deviceInfo}`,
    {},
  );
}

export async function validateSMS(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const result = await postJson<string>(
    `/${GATEWAY_PATH}/Password/ForgotMfaVerifySms?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}&oneTimePass=${encodeURIComponent(pin)}`,
    {},
  );
  return {
    status: +result,
  };
}

export async function validateCall(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const result = await postJson<string>(
    `/${GATEWAY_PATH}/Password/ForgotMfaVerifyCall?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}&oneTimePass=${encodeURIComponent(pin)}`,
    {},
  );
  return {
    status: +result,
  };
}

export async function validateFa(
  challenge: MfaChallenge,
  pin: string,
): Promise<MfaResult> {
  const result = await postJson<string>(
    `/${GATEWAY_PATH}/Password/ForgotMfaVerifyHelpDesk?loginId=${encodeURIComponent(challenge.data.loginId)}&mfaSession=${encodeURIComponent(challenge.session)}&oneTimePass=${encodeURIComponent(pin)}`,
    {},
  );
  return {
    status: +result,
  };
}

export async function updatePassword(
  password: string,
  session: string,
): Promise<void> {
  await putJson<ForgotMfaResponse>(
    `/${GATEWAY_PATH}/Password/ForgotMfaChange?mfaSession=${encodeURIComponent(session)}`,
    {
      newPassword: password,
    },
  );
}

export async function getImpersonationJWT(token: string, pin: string) {
  const result = await postJson<ImpersonateResult>(
    `/${GATEWAY_PATH}/Impersonate`,
    { pin, token },
  );
  return result;
}

export async function impersonateLogin(
  token: string,
  pin: string,
): Promise<string> {
  const jwtResult = await getImpersonationJWT(token, pin);
  return jwtResult.jwt;
}


